import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_WhatToDo = () => {
    const seo = {
        metaDesc: 'What To Do After The USPTO Has Issued Your Patent? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What To Do After The USPTO Has Issued Your Patent? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What To Do After The USPTO Has Issued Your Patent?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>What To Do After The USPTO Has Issued Your Patent?</h2>
                                <h3><b style={{ fontSize: 16 + 'px' }}>1. What you can do now</b></h3>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> Enforcement</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>As earlier stated, while the application provides protection for the invention,
                                    until the application is published, you should still require confidentiality agreement to protect your
                                    invention. Again, you should keep the serial number and filing date a secret.&nbsp; This helps you enforce
                                    you rights, which you usually protect on your own.&nbsp; Of course, if needed, you can resort to the U.S.
                                    federal court system to help enforce your rights as well.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> basics</i></b></li>
                                </ol>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> direct and indirect infringement</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>You want to protect yourself from infringement.&nbsp; Any infringement action
                                    needs to be brought to court within six years of when the infringement occurred.&nbsp; That’s the rule
                                    regardless if it’s a direct or indirect infringing act.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Here’s a brief explanation of the difference between direct and indirect
                                    infringement.&nbsp; First, direct infringement is where someone makes, uses, sells or imports the invention
                                    without the patent owner’s permission.&nbsp; It is also when someone designs around claims but achieves the
                                    same function in the same manner with the same result; this is known as equivalent infringement.&nbsp;
                                    Second, indirect infringement is when someone encourages another to directly infringe.&nbsp; Additionally,
                                    it includes when someone sells a material part of patented invention, knowing it will be used for an
                                    infringing act.&nbsp; Under indirect infringement, you still need someone to actually do a directly
                                    infringing act.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Note that pure research done by an academic institution, for example, is not an
                                    infringing act.&nbsp; Also, compounds reasonably related to governmental submissions are not considered
                                    infringing.</span></p>
                                <p>&nbsp;</p>
                                <p><span style={{ fontWeight: 400 }}>Design patent infringement</span></p>
                                <p><span style={{ fontWeight: 400 }}>A design patent doesn’t include claims but it includes drawings.&nbsp; Drawings
                                    are much more important than the written descriptions for design patent applications.&nbsp; So, the standard
                                    to claim design patent infringement is if the design looks substantially similar to the drawings in the
                                    patent application.&nbsp; The court will first look at whether the design between the patented item and
                                    alleged infringer would deceive an ordinary person into buying the infringer.&nbsp; If so, then it’s more
                                    likely that it is a design patent infringement.&nbsp; The court will then compare the patented design
                                    against prior art to assess whether it is novel.&nbsp; Lastly, it analyzes whether the alleged infringing
                                    design is novel.&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> infringing claims</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>To claim infringement, the infringing item must meet all elements of a specific
                                    claim.&nbsp; For example, if an item includes A, B and C and the claim protects A, B and C, then the item
                                    infringes.&nbsp; It also infringes if it includes A, B, C and D.&nbsp; However, it doesn’t infringe, if it
                                    includes just A and B, or one element is materially different than what is claimed.</span></p>
                                <p>&nbsp;</p>
                                <p><b><i>iii. doctrine of equivalents</i></b></p>
                                <p><span style={{ fontWeight: 400 }}>The court can also find infringement under the doctrine of equivalents,
                                    regardless of claims.&nbsp; This doctrine says if two or more inventions are adequately equivalent in what
                                    they do and how they do it, there is infringement.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>The inverse is also true, though rare:&nbsp; If two or more inventions have the
                                    same claims but are adequately different in what they do and how they do it, there is no
                                    infringement.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> intent and knowledge</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>The court does not analyze intent and knowledge when it studies direct
                                    infringement.&nbsp; However, those two come into play when the court is contemplating damages for the
                                    infringing act and indirect infringement.&nbsp; Regarding indirect infringement, for example, one must sell
                                    an infringing part to someone with the knowledge that person is going to create an infringing
                                    item.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>If one intentionally and knowingly infringes, then the court generally increases
                                    damages he/she must pay; these damages can include attorney costs and be three times the amount of what the
                                    court would typically tell the infringer to pay.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> timing</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>You can only claim infringement during the patent’s term.&nbsp; You cannot claim
                                    it when the patent is pending, which is the period between when you file your patent application and
                                    actually receive a patent.</span></p>
                                <p><span style={{ fontWeight: 400 }}>On the other hand, if you have your application published early, then you can
                                    claim infringement if it happens after your application’s publication date.&nbsp; You must show that the
                                    infringer actually saw your published application, and the USPTO later issued you a patent for this
                                    invention.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> targets of a suit</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>For alleged infringing acts, you can sue the manufacture of the infringing item,
                                    the seller and the buyer.&nbsp; Furthermore, you can sue the employer of the infringer under the theory of
                                    respondeat superior.&nbsp; This is if the infringer was acting under the scope of his/her employment duties,
                                    the infringer acts under the authority of the employer, and the employer benefits or approves of the
                                    activity.&nbsp; Additionally, you can sue a company if it has purchased another company that has infringed;
                                    this is known as successor liability.&nbsp; To prove successor liability, you need to show that the two
                                    companies agreed that liability would pass to the purchasing company, the two merged, the purchasing company
                                    is continuing the business of the other, or the sale of the company is fraudulent.</span></p>
                                <p>&nbsp;</p>
                                <p><span style={{ fontWeight: 400 }}>Government infringement</span></p>
                                <p><span style={{ fontWeight: 400 }}>You can bring suit against the federal government for infringing.&nbsp; However,
                                    rewards are limited, meaning you can’t halt the government’s infringing activity and you only are awarded
                                    financial damages plus interest.&nbsp; In contrast, state governments aren’t liable for
                                    infringement.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <p><b><i>vii. non-U.S. infringement</i></b></p>
                                <p><span style={{ fontWeight: 400 }}>A U.S. patent only protects a patent owner from what happens in U.S.
                                    territories.&nbsp; To protect yourself from infringing activity in foreign countries, you would have had to
                                    file for a patent in that foreign country.&nbsp; The method to do that will be discussed later.&nbsp;
                                    Nonetheless, a U.S. patent does bar importing, using or selling an infringing item into the United States;
                                    and manufacturing all parts of U.S. patented invention and then shipping the parts to the United States with
                                    assembly instructions.&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> litigation</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Sometimes you need to bring your infringer to court to enforce your patent
                                    rights.&nbsp; Here are the basics of how litigation may operate in your suit.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b> </b><b><i>ceases and desist letter</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>You will probably send this letter first.&nbsp; A cease and desist letter tells
                                    the alleged infringer about your patent, requests payment such as royalties or damages for previous
                                    infringement, and asks the infringer to stop infringing.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> venue</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Patent disputes are heard in federal court.&nbsp; If you’re suing the federal
                                    government, your case is held in the Court of Claims in Washington D.C.&nbsp; If you’re suing a person or
                                    company, you can pick the location to bring suit.&nbsp; You can file your complaint, summons and civil cover
                                    sheet where the defendant resides, or where the infringement occurred and where he/she has established
                                    regular business.&nbsp; A business resides where it is incorporated, does business or has a license to do
                                    business.</span></p>
                                <p>&nbsp;</p>
                                <p><b><i>iii. answer and counterclaims</i></b></p>
                                <p><span style={{ fontWeight: 400 }}>After you pick your venue and file your complaint, your alleged infringer will
                                    respond to your complaint via an answer.&nbsp; He/she will either agree with your allegations or deny
                                    them.&nbsp; Usually, his/her defense will be that your patent is an invalid; for example, it isn’t novel or
                                    non-obvious.&nbsp; He/she may also assert counterclaims, which are actions he/she is bringing against
                                    you.&nbsp; The alleged infringer must present these counterclaims now because any claims that arise out of
                                    this situation are barred if he/she tries to do so later.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> discovery</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If you’re still proceeding with your suit, next comes the discovery phase where
                                    each side tries to look for information to present at trial.&nbsp; This is sometimes accompanied by
                                    protective orders, which a court issues to protect sensitive, confidential information from being shared or
                                    leaked to the public.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> trial and appeal</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>The culmination of this process is the trial.&nbsp; If you are unhappy with the
                                    outcome, you appeal to the CAFC.&nbsp; And if you are still unhappy, you appeal to the U.S. Supreme
                                    Court.&nbsp;&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> remedies and damages</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If a suit works in your favor, you may receive the following:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>injunction, which is a court order that stops the
                                        infringing activity.&nbsp; A permanent injunction is usually ordered after trial if you win.&nbsp;
                                        [k:&nbsp; wasn’t sure the best place to put the following] To stop infringement while the case is still
                                        pending, you need to file a temporary restraining order (TRO).&nbsp; This is usually granted if the
                                        judge believes one party will destroy evidence.&nbsp; After a TRO, you should file for a preliminary
                                        injunction which is usually in effect when each side is presenting evidence.&nbsp; It lasts until the
                                        court reads its judgment.&nbsp; When granting one, the court looks at whether the patent owner will win
                                        and if he/she will suffer irreparable harm if he or she doesn’t have a preliminary injunction.&nbsp; For
                                        both a TRO and preliminary injunction, the patent owner usually needs to pay a bond, which covers any
                                        costs incurred if he/she loses the case.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Damages, which are generally shown by looking at
                                        how many sales the patent owner lost to the infringer and profits gained by the infringer.&nbsp; The
                                        court sometimes also looks at lost royalties, the money the patent owner would’ve gained if he/she
                                        licensed the patented item to the infringer.</span></li>
                                    <li style={{ fontWeight: 400 }}></li>
                                </ul>
                                <ol>
                                    <li><b><i> alternatives to litigation</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Litigation can be extremely costly and time consuming, so there are alternatives
                                    to it.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> settlement</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Before litigation starts or even during, the parties may agree to settle the
                                    matter outside of court.&nbsp; Settlements may be a good alternative because they save time and money, and
                                    the amount agreed upon is guaranteed to be paid.&nbsp; Damages resulting from a court decision, on the other
                                    hand, have to be enforced and you might be out of luck if the infringer has no money, even if you’ve won the
                                    case.</span></p>
                                <p>&nbsp;</p>
                                <ol>
                                    <li><b><i> mediation and arbitration</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Arbitration and mediation also saves time and money.&nbsp; Mediation involves the
                                    two parties sitting in front of a neutral mediator to reach a settlement that they can both be content
                                    with.&nbsp; Arbitration is also in front of a neutral decision maker, whose decision is usually binding and
                                    final.&nbsp; You may agree to these instead of litigation, or you may have previously agreed to a contract
                                    with terms that say you must do this instead of litigating.&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li><b><i> Marking</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>As earlier noted, as soon as the USPTO mails your filing receipt, your
                                    application is considered pending. &nbsp; Once a patent is issued, the patented product can be marked with
                                    the word “U.S. Patent”, “Patent”, or “Pat.” and the patent’s number. This notifies the world that the
                                    product is protected by the patent system. The benefit of marking is that the patent owner can seek damages
                                    without proof that the infringer had actual notification of the patent.</span></p>
                                <p><span style={{ fontWeight: 400 }}>However, some choose not to mark their item with the patent number.&nbsp; Instead
                                    they may just use “Patent,” “Pat.” or “U.S. Patent.”&nbsp; That’s because this patent number makes it easy
                                    for people to find the published patent application, review how the item is made and find a way to invent
                                    around the patent.&nbsp; If you decide to omit the patent number in your marking, be aware that you have a
                                    weak argument in proving notice in an infringement case.</span></p>
                                <p>&nbsp;</p>
                                <ol start="3">
                                    <li><b><i> License or sell the patent</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Patents have the attributes of personal property.&nbsp; Consequently,
                                    applications for patent, patents, or any interest therein can be assignable in law by an instrument in
                                    writing. The applicant, patentee, assigns or legal representatives may grant and convey an exclusive right
                                    under his/her application for patent, either the whole or any specified part of it.</span></p>
                                <p><span style={{ fontWeight: 400 }}>Here are the differences between selling and licensing:&nbsp; When you sell the
                                    patent, you are transferring your patent title to the buyer. You do not have the patent title anymore. On
                                    the other hand, with a license, you still retain ownership and typically collect royalties from the
                                    licensee.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Note some details for licenses:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>can last for the entire patent term or just a part
                                        of it;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>can be exclusive or non, meaning you’ve granted
                                        licenses one person versus multiple people or organizations respectively;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>can be use specific;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>can be location specific;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>the person you give a license to may be able to
                                        transfer it to a third-party depending on the agreement you have with that person; and</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>companies often trade licenses, which is known as
                                        cross-licensing.</span></li>
                                </ul>
                                <p><span style={{ fontWeight: 400 }}>To be effective against any subsequent purchaser or mortgagee for valuable
                                    consideration, you should record the patent or application assignment with the USPTO within three months
                                    from its date or prior to the date of such subsequent purchase or mortgage.&nbsp; If not, then the action
                                    “is void against a subsequent purchaser for a valuable consideration without notice, unless it is recorded
                                    prior to the subsequent purchase,” the USPTO explains.</span></p>
                                <p><span style={{ fontWeight: 400 }}>The recording must identify the patent number and date if available; or the
                                    application number, inventor’s name, title of invention as noted in the application, and date of filing if
                                    applicable.</span></p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li><b><i> Publicity&nbsp;</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>After you request and pay a fee to the USPTO, the USPTO can publish a notice in
                                    the Official Gazette that the patent is available for sale or licensing. Other than that, it is up to you to
                                    take the initiative and publicize your patent.</span></p>
                                <p><span style={{ fontWeight: 400 }}>The Small Business Administration (SBA) may be a helpful resource.&nbsp; It
                                    provides excellent information on starting, planning, marketing, obtaining venture capital and financing a
                                    small business. The SBA also provides training and counseling on SBA loan programs. You can also get help
                                    from your local Small Business Development Centers (SBDC)&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>You can also visit Invention Development Companies or Invention Promotion Firms,
                                    which may help you bring your invention to the marketplace. However, carefully check the background of these
                                    organizations before you work with them. You can google their names to see what experience other inventors
                                    have had with them. You can also see if there are any published complaints at </span><a
                                        href="http://www.uspto.gov/inventors/scam_prevention/complaints/"><span
                                            style={{ fontWeight: 400 }}>http://www.uspto.gov/inventors/scam_prevention/complaints/</span></a><span
                                                style={{ fontWeight: 400 }}>&nbsp;</span></p>
                                <p>&nbsp;</p>
                                <ol start="5">
                                    <li><b><i> save for future maintenance fees</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>You pay maintenance fees to the USPTO to maintain a granted patent. These fees
                                    are due 3 1/2, 7 1/2 and 11 1/2 years from the date of the original patent grant.&nbsp; If you don’t pay
                                    these fees, you lose your patent, meaning it expires and your invention falls into the public domain.</span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="6">
                                    <li><b><i> reissue application</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If you think there’s something wrong or incorrect with your patent, such as your
                                    claims aren’t broad enough or are too broad, then you should file a reissue application.&nbsp; You can
                                    usually file this at any time during the term of your patent.&nbsp; But if you are arguing to broaden your
                                    claims, then you must file this within two years after the USPTO issued your patent.</span></p>
                                <p><span style={{ fontWeight: 400 }}>The reissued patent is supposed to correct defects in the earlier patent.&nbsp;
                                    It lasts whatever time is left on your earlier patent.&nbsp; Be aware that the USPTO will reexamine all
                                    claims asserted under your earlier patent, so an examiner could strike down those previous claims during
                                    this examination.</span></p>
                                <h3><b>2. What others may do</b></h3>
                                <ol>
                                    <li><b><i> others can challenge your approved patent via prior art</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>A challenge can be initiated by another patent owner, the public, or the USPTO
                                    Director, who submits a request for reexamination if there is a substantial new question of patentability.
                                    The USPTO’s Central Reexamination Unit then looks at whether the patent should have been issued in light of
                                    prior art.&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>This argument can be brought at any time.&nbsp; This includes while a patent
                                    application is pending.&nbsp; At that time, third-parties may submit relevant printed documents as prior art
                                    with an explanation of the relevance of the documents for consideration by the examiner.</span></p>
                                <p><span style={{ fontWeight: 400 }}>If your opponent is successful in his/her challenge, then your invention isn’t
                                    patented anymore and falls into the public domain, meaning it isn’t protected anymore.</span></p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li><b><i> others can challenge your standing</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If you are in patent litigation, the defendant can claim the plaintiff, who is
                                    believed to be the patent owner, lacks standing.&nbsp; The defendant is basically stating that the plaintiff
                                    actually does not own the patent so he or she cannot bring suit for rights he or she doesn’t have.</span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="3">
                                    <li><b><i> others can claim the first sale exemption or exhaustion doctrine</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>The “first sale exemption” or “exhaustion doctrine” means that once you sell a
                                    patent, you’ve exhausted your rights to it, so it is not infringement if the patent is later resold.&nbsp;
                                    This is not a valid argument if the initial sale of the patent is done without permission of the patent
                                    owner.</span></p>
                                <p>&nbsp;</p>
                                <ol start="4">
                                    <li><b><i> others can claim the repair doctrine</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>If someone repairs a patented device and replaces unpatented items, that is not
                                    illegal.&nbsp; It is also not illegal for someone to sell items that are used to repair and replace worn out
                                    parts.&nbsp; However, it is illegal if an item is made or sold without the patent owner’s permission.</span>
                                </p>
                                <p>&nbsp;</p>
                                <ol start="5">
                                    <li><b><i> others can say you misused your patent</i></b></li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>Besides the above, others may assert that you:</span></p>
                                <ul>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>mismarked your patented item;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>committed fraud against the USPTO, such as by
                                        lying or patenting something that actually doesn’t work;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>illegally or unfairly licensed your patented
                                        item;&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>delayed infringement litigations so long that
                                        you’ve hurt the alleged infringer; or</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>used the patented item in an illegal way.</span>
                                    </li>
                                </ul>
                                <p><span style={{ fontWeight: 400 }}>After new patents issue, third-parties may challenge the patent’s validity for
                                    any of the above reasons if this is done within nine months of the patent’s issuance date. After the nine
                                    month period, however, challenges can only be brought on the basis that the patented invention is not new
                                    and/or is obvious.</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_WhatToDo;